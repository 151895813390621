import axios from "axios";

export const fetchHeaderData = async () => {
  return getResponse("data/header.json");
};
export const fetchFeaturesData = async () => {
  return getResponse("data/detailedFeatures.json");
};
/*
 * Home Index Components datas
 */
export const fetchHomeDescriptionData = async () => {
  return getResponse("data/description.json");
};
export const fetchRoadmapData = async () => {
  return getResponse("data/roadmap.json");
};

export const fetchHomeFeaturesData = async () => {
  return getResponse("data/homeFeatures.json");
};
export const fetchClientsData = async () => {
  return getResponse("data/clients.json");
};
export const fetchGettingStartedData = async () => {
  return getResponse("data/getStarted.json");
};
/*
 * Homeindexes End
 */
export const fetchFaqData = async () => {
  return getResponse("data/faq.json");
};
export const fetchVideosData = async () => {
  return getResponse("data/videosData.json");
};
export const fetchPricingData = async () => {
  return getResponse("data/pricingData.json");
};
export const fetchContactsData = async () => {
  return getResponse("data/contacts.json");
};
export const fetchTrialData = async () => {
  return getResponse("data/trial.json");
};
export const fetchFooterData = async () => {
  return getResponse("data/footer.json");
};
export const fetchCookieData = async () => {
  const cookieAccepted = window.localStorage.getItem("cookieAccepted");
  if (!cookieAccepted || cookieAccepted !== "true") {
    return getResponse("data/cookieData.json");
  }
  return { cookieAccepted: true };
};

const getResponse = async (url) => {
  const response = await axios.get(url, headerConfigNoChache);
  const data = response.data;
  return data;
}
const headerConfigNoChache = {
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  }
};