import React, {
  useRef, useEffect,
  useContext,
  Suspense,
} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import "swiper/css/effect-creative";

import {
  NavLink,
  NavLinks,
  NavToggle,
  LogoLink,
  DesktopNavLinks,
  HeaderNavigation,
} from "./Components/NavigationComponent.jsx";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual,
} from "swiper/core";
import { EffectCreative } from "swiper";
import { RouterLink } from "@/components/utilities/RouterLink.jsx";
import { LanguageContext } from "@/store/langContext.jsx";
import { ResponsiveVideoEmbed } from "@/helpers/ResponsiveVideoEmbed.jsx";
import { setVideoLinksWithoutCookie } from "@/helpers/helperFunctions";
import { InternalLink, ExternalLink } from "../../utilities/RenderLinks.jsx";
import { fetchHeaderData } from "../../../helpers/queries.js";
import { useQuery } from "react-query";

const NavigationContainer = tw.div`w-full fixed top-0 left-0 z-30 bg-primary-700/[.90] backdrop-blur-sm`;
const StyledHeader = styled(HeaderNavigation)`
  ${tw`max-w-screen-xl px-6 py-4 sm:px-16 `}
  ${DesktopNavLinks} ${NavLink},
    ${LogoLink} {
    ${tw`text-gray-200 hover:border-gray-300 hover:text-gray-100`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  min-height: 650px;
  ${tw`flex flex-col items-center justify-center`}
  @media (max-width: 768px) {
    min-height: 500px;
  }
  ${tw`relative p-8 -mt-8 bg-center bg-cover`}
  background-image: url("/img/banner1.webp");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500/[0.35]`;

const HeroContainer = tw.div`z-20 relative sm:px-8 max-w-screen-lg px-4 mt-20`;



const StyledIcon = tw.span`inline-block mr-4 text-primary-500 bg-white rounded-full shadow shadow-white fill-current text-xl p-2`;
const Logo = (
  <Link to="/" className="logoContainer" aria-label="Home">
   <StyledIcon>SLR</StyledIcon>
    SLRProject
  </Link>
);


const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`};

  iframe {
    ${tw`bg-black rounded shadow-xl`}
  }
`;


SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

export const MainHeader = () => {
  const { isError, isLoading, data, error } = useQuery(
    "HeaderData",
    fetchHeaderData
  );
  const { language, changeLanguage, getTranslatedData } = useContext(LanguageContext);
  const [width, setWidth] = React.useState(720);
   
    const handleResize = () => {
      let innerWidth = window.innerWidth;
      if(innerWidth > 768){
         return setWidth(720);
      }
      return setWidth(innerWidth * 0.8);
    }
    useEffect(() => {
      handleResize()
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [])
  if (isLoading) return null;
  const prepareLinks = (linksgroup) => {
    return linksgroup.map((links, i) => (
      <NavLinks key={i}>
        {links.map((link) =>
          link.button && link.button === "changeLanguage" ? (
            <button
              onClick={() => changeLanguage(link.value)}
              key={link.value}
              aria-label={getTranslatedData(link.title)}
              className={`link button ${
                language === link.value ? " active" : ""
              }`}
            >
              {getTranslatedData(link.title)}
            </button>
          ) : (
            <RouterLink
              url={link.url}
              key={link.url}
              title={link.title}
              aria-label={getTranslatedData(link.title)}
              lang={language}
              primary={link.primary}
            />
          )
        )}
      </NavLinks>
    ));
  };

  return (
    <Suspense>
      <Container className="relative flex items-center justify-center">
        <OpacityOverlay />
        <HeroContainer className="w-full max-w-screen-sm mx-auto ">
          <NavigationContainer>
            <StyledHeader links={prepareLinks(data.navlinks)} logoLink={Logo} />
          </NavigationContainer>
          <div className="relative w-full h-full pb-[56.25%] ">
             <iframe width={width} height={width*9/16} src="https://www.youtube.com/embed/wlSa98OO20w" title="YouTube video player" className="absolute top-0 left-0 w-screen h-full"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </div>
          
        </HeroContainer>
      </Container>
    </Suspense>
  );
};
