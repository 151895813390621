import React from "react";
import tw from "twin.macro";
import loadable from "@loadable/component";
import "swiper/css";
import "swiper/css/bundle";
import "@/styles/styles.scss";

import GlobalStyles from "@/styles/GlobalStyles.jsx";
import { MainHeader } from "@/components/Template/Header/Header.jsx";

import { LanguageContextProvider } from "@/store/langContext.jsx";

const JumpToTop = loadable(
  () => import("@/components/template/JumpToTop.jsx"),
  { resolveComponent: (components) => components.JumpToTop }
);
const CookieComponent = loadable(
  () => import("@/components/template/Cookie/CookieComponent.jsx"),
  { resolveComponent: (components) => components.CookieComponent }
);
const RoutesComponent = loadable(
  () => import("@/components/Router/RoutesComponent.jsx"),
  { resolveComponent: (components) => components.RoutesComponent }
);
const RouteScrollToTop = loadable(
  () => import("@/components/Router/RouteScrollToTop.jsx"),
  { resolveComponent: (components) => components.RouteScrollToTop }
);

const StyledDiv = tw.div`font-display`;

export const App = () => (
  <LanguageContextProvider>
    <GlobalStyles />
    <StyledDiv>
      <RouteScrollToTop />
      <RoutesComponent />
      <JumpToTop />
      <CookieComponent />
    </StyledDiv>
  </LanguageContextProvider>
);
